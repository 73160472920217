
<i18n locale="th" lang="yaml" >
 page.title : "{name} :: Duplicate Lot "
 page.description : "ทำการสร้าง Drone Lot ใหม่ โดยใช้ข้อมูลแบบ {name} สำหรับรุ่น {modelName}"
 duplicate_lot.success : "ทำการสร้าง Drone Lot ใหม่เรียบร้อย"

 duplicate_lot.submit.label : "เพิ่ม Lot ใหม่"
 duplicate_lot.confirm.title : "ยืนยันการสร้าง Lot"
 duplicate_lot.confirm.message : "คุณต้องการสร้าง Drone Lot ใหม่ สำหรับ รุ่นนี้  ?"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title',{ name: lot.lot_name })"
			:description="$t('page.description',{ name: lot.lot_name , modelName: model.name })"/>

		<a-card :bordered="false">
			<DroneLotForm
				ref="formRef"
				:loading="loading"
				mode="create"
				@submit="handleSubmit">
				<template v-slot:submitSlot="{ formLoading }">
					<a-button
						:disabled="formLoading"
						icon="left"
						size="large"
						:style="{ 'margin-right': '4px' }"
						@click="goBack">
						{{$t('common.back')}}
					</a-button>
					<a-button
						:loading="formLoading"
						type="info"
						html-type="submit"
						class="btn-submit"
						icon="edit"
						size="large">
						{{$t('duplicate_lot.submit.label')}}
					</a-button>
				</template>
			</DroneLotForm>
		</a-card>
	</div>
</template>

<script>
import PageMixin from '@mixins/PageMixin.vue'
import DroneLotForm from '@components/drone/DroneLotForm.vue'
import axios from "axios"

export default {
	page() {
		return {
			title : this.$t('page.title',{name:this.lot.lot_name})
		}
	} ,
	components : {
		DroneLotForm,
	} ,
	mixins: [PageMixin],
	data(){
		return {
			model : {} ,
			lot : {},
			loading : false,
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		fetchData() {
			const lotId = this.$route.params.id;
			if (!lotId)
				return

			this.showPageLoading(true)
			this.clearBreadcrumbParams(['lotName','modelName','modelId'])
			axios.get("/api/drone-lots/"+lotId).then((response)=>{
				this.lot = response.data.data.drone_lot;
				this.model = response.data.data.drone_model;

				const lotForm = {...this.lot}
				lotForm.lot_name = lotForm.lot_name + " [Copy]"
				lotForm.manufacture_date = this.$dayjs().format()
				this.$refs.formRef.updateLotForm(lotForm)

				this.addBreadcrumbParams({
					'modelName' : this.model.name ,
					'modelId' : this.model.id,
					'lotName' : this.lot.lot_name
				})
			}).catch((error) => {
				this.fetchError(error);
			}).finally(()=>{
				this.hidePageLoading()
			})
		} ,
		handleSubmit(formData) {
			this.$confirm({
				title : this.$t('duplicate_lot.confirm.title') ,
				content : this.$t('duplicate_lot.confirm.message') ,
				maskClosable : true,
				onOk : () => {
					this.loading = true
					axios.post("/api/drone-lots/"+this.lot.id+"/duplicate",formData).then((response) => {
						const newLot = response.data.data.drone_lot
						this.$router.push({
							name : 'drone_lot/view',
							params : {id : newLot.id}
						});
						this.$message.success(this.$t('duplicate_lot.success',{"name" : newLot.lot_name,"modelName" : this.model.name}))
					}).catch((error) => {
						this.$refs.formRef.formErrors(error)
					}).finally(()=>{
						this.loading = false;
					});
				}
			})
		} ,
		goBack() {
			this.$open({name:'drone_lot/view',params:{id:this.lot.id}})
		}
	} ,
}
</script>
