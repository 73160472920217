<i18n src="@i18n/drone/drone_lot.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
drone_lot.field.lot_name.help : "ชื่อ Lot สำหรับ Drone Model นี้ เช่น L2020-02-1"
drone_lot.field.lot_name.placeholder : "เช่น L2020-02-1"
drone_lot.field.manufacture_date.help : "วันผลิต Drone Lot นี้ ระบุเพื่อสามารถตรวจสอบการสั่งอะไหล่ได้"
drone_lot.field.remark.placeholder : "เช่น Lot นี้มีการเปลี่ยน Supplier ในบางชิ้นส่วน เป็นต้น"
</i18n>

<template>
	<a-form
		layout="horizontal"
		:label-col="labelCol"
		:wrapper-col="wrapperCol"
		:form="formObj"
		@submit.prevent="handleSubmit">
		<a-form-item :label="$t('drone_lot.field.lot_name')"
			:extra="$t('drone_lot.field.lot_name.help')">
			<a-input
				v-decorator="[
					'lotName' ,
					{ rules: [
						{ required: true ,whitespace: true ,message: $tt('validate.required','drone_lot.field.lot_name') },
					] },
				]"
				type="text"
				:placeholder="$t('drone_lot.field.lot_name.placeholder')"/>
		</a-form-item>

		<a-form-item :label="$t('drone_lot.field.manufacture_date')"
			:extra="$t('drone_lot.field.manufacture_date.help')">
			<MyDatePicker
				v-decorator="['manufactureDate',
						{
							rules: [
								{ required: true ,whitespace: true ,message: $tt('validate.required','drone_lot.field.manufacture_date') },
							] ,
						}]"
				format="DD MMMM YYYY"/>
		</a-form-item>

		<hr/>
		<a-form-item :label="$t('drone_lot.field.remark')">
			<a-textarea
				v-decorator="['remark']"
				:auto-size="{ minRows: 3,maxRows: 8 }"
				:placeholder="$t('drone_lot.field.remark.placeholder')">
			</a-textarea>
		</a-form-item>

		<a-form-item v-bind="wrapperOnlyLayout">
			<slot name="submitSlot" :formLoading="loading">
				<a-button
					:loading="loading"
					type="info"
					html-type="submit"
					class="btn-submit"
					size="large">
					{{$t('common.save')}}
				</a-button>
			</slot>
		</a-form-item>
	</a-form>
</template>

<script>
import HasAntdFormMixin from '@mixins/HasAntdFormMixin.vue'
import MyDatePicker from '@components/input/MyDatePicker.vue'
import { updateFieldsValue } from '@utils/formUtil'
import ApiError from '@utils/errors/ApiError'

export default {
	components : {
		MyDatePicker ,
	} ,
	mixins : [HasAntdFormMixin] ,
	props : {
		mode : {
			type : String ,
			default : 'update'
		} ,
		loading : {
			type : Boolean ,
			default : false
		}
	} ,
	methods : {
		handleFileUploadError(error) {
			this.$message.error(ApiError.getDisplayErrorMessage(this,error));
		} ,
		hadleFileUploadChange(value) {
			this.imageUploadData = value;
		} ,
		handleSubmit() {
			this.formObj.validateFields((err,values) => {
				if (!err) {
					const formData = {...values};
					this.$emit('submit',formData)
				} else {
					this.displayValidateErrorMessage()
				}
			})
		} ,
		updateLotForm(lot) {
			updateFieldsValue(this.formObj,lot)
		} ,
		formErrors(error) {
			this.formSubmitErrors(error)
		}
	}
}
</script>
