var render = function render(){var _vm=this,_c=_vm._self._c;return _c('a-form',{attrs:{"layout":"horizontal","label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol,"form":_vm.formObj},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":_vm.$t('drone_lot.field.lot_name'),"extra":_vm.$t('drone_lot.field.lot_name.help')}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
				'lotName' ,
				{ rules: [
					{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','drone_lot.field.lot_name') },
				] },
			]),expression:"[\n\t\t\t\t'lotName' ,\n\t\t\t\t{ rules: [\n\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','drone_lot.field.lot_name') },\n\t\t\t\t] },\n\t\t\t]"}],attrs:{"type":"text","placeholder":_vm.$t('drone_lot.field.lot_name.placeholder')}})],1),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone_lot.field.manufacture_date'),"extra":_vm.$t('drone_lot.field.manufacture_date.help')}},[_c('MyDatePicker',{directives:[{name:"decorator",rawName:"v-decorator",value:(['manufactureDate',
					{
						rules: [
							{ required: true ,whitespace: true ,message: _vm.$tt('validate.required','drone_lot.field.manufacture_date') },
						] ,
					}]),expression:"['manufactureDate',\n\t\t\t\t\t{\n\t\t\t\t\t\trules: [\n\t\t\t\t\t\t\t{ required: true ,whitespace: true ,message: $tt('validate.required','drone_lot.field.manufacture_date') },\n\t\t\t\t\t\t] ,\n\t\t\t\t\t}]"}],attrs:{"format":"DD MMMM YYYY"}})],1),_vm._v(" "),_c('hr'),_vm._v(" "),_c('a-form-item',{attrs:{"label":_vm.$t('drone_lot.field.remark')}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark']),expression:"['remark']"}],attrs:{"auto-size":{ minRows: 3,maxRows: 8 },"placeholder":_vm.$t('drone_lot.field.remark.placeholder')}})],1),_vm._v(" "),_c('a-form-item',_vm._b({},'a-form-item',_vm.wrapperOnlyLayout,false),[_vm._t("submitSlot",function(){return [_c('a-button',{staticClass:"btn-submit",attrs:{"loading":_vm.loading,"type":"info","html-type":"submit","size":"large"}},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t('common.save'))+"\n\t\t\t")])]},{"formLoading":_vm.loading})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }